import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BoutonAcheterTirageAvecPrix from "../../../composants/boutique/BoutonAcheterTirageAvecPrix"
import LayoutGalerieImageInteractive from "../../../composants/layout/LayoutGalerieImageInteractive"
import Pannellum from "../../../composants/image/Panellum"

import {
  obtenirPage,
  obtenirImage,
  obtenirImages,
} from "../../../js/client-es/utils"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    page: allPagesCompilationYaml(filter: { code: { eq: "yeuxDuSalar360" } }) {
      ...FragmentPageYaml
    }
    imageAVendre: allImagesCompilationYaml(
      filter: { code: { in: ["voieLacteeSourceUnivers"] } }
    ) {
      nodes {
        ...FragmentDonneesImages
      }
    }
  }
`

export default function Teide360(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "page")
  const images = obtenirImages(resultatsRequete, "imageAVendre")

  const composantImageInteractive = (
    <Pannellum urlImageSource="/ressources/360/yeuxDuSalar.jpg" />
  )

  return (
    <LayoutGalerieImageInteractive
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360Degres"
    >
      <section className="section-conteneur">
        <p>
          Au petit matin vers 6h30, la Voie Lactée se reflétait dans un petit
          trou d’eau parfaitement calme dans le désert d’Atacama au Chili. Dans
          le noir complet, seule la lumière émise par le plus beau ciel étoilé
          du monde nous permettait de trouver notre chemin. L’ambiance était
          magique. Un calme incroyable régnait, tout juste perturbé par quelques
          aboiements de chiens lointains. Clou du spectacle, derrière nous, une
          très intense lumière zodiacale éclairait les Andes…
        </p>
        <div id="galerie-image-interactive-texte-exifs">
          Panoramique de 29 images, rotule panoramique Nodal Ninja 3, Canon EOS
          6D refiltré Astrodon, Samyang 24 mm F1.4 @ F2.2, 3200 ISO. Poses
          unitaires de 20 secondes.
        </div>
        <div id="galerie-image-interactive-conteneur-achat-tirage-avec-prix">
          <BoutonAcheterTirageAvecPrix
            image={obtenirImage(images, "voieLacteeSourceUnivers")}
            langue={page.langue}
          />
        </div>
      </section>
    </LayoutGalerieImageInteractive>
  )
}
